<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-dialog" role="document" style="max-width: 750px;">
        <div class="modal-content">
          <div class="modal-header d-flex justify-content-between">
            <h5 class="modal-title">{{title}}</h5>
            <div class="d-flex">
              <button class="btn btn-secondary" @click="close">Cancel</button>
              <button class="btn btn-primary ml-3" @click="saveForm">Save</button>
            </div>
          </div>
          <div class="modal-body p-3" style="padding: 0px; text-align:left;">
            <!-- <div class="pb-3" v-for="item in formFields" :key="item.id"> -->
              <!-- <h5 style="font-weight: 700;">{{item.name}}</h5> -->
              <p>All fields are required</p>
              <div class="row">
                <div :class="fields.col" v-for="fields in formFields" :key="fields.id">
                <div v-if="fields.type == 'toggle-btn'">
                  <h4 style="font-weight: 400; font-size: 14px; margin-bottom: 5px; margin-top:10px;">{{fields.label}}</h4>
                  <label class="switch">
                    <input type="checkbox" v-model="inputForm[fields.vmodel]">
                    <div class="slider round"></div>
                  </label>
                </div>

                <div v-if="fields.type == 'dropdown'">
                  <h4 style="font-weight: 400; font-size: 14px; margin-bottom: 5px; margin-top:10px;">{{fields.label}}</h4>
                  <multiselect 
                    :options="fields.data" 
                    :allow-empty="true" 
                    :show-labels="false" 
                    :placeholder="fields.placeholder" 
                    v-model="inputForm[fields.vmodel]" 
                    @input="handleDropdownChange($event, fields)">
                  </multiselect>
                </div>

                <div v-if="fields.type == 'custom-dropdown'">
                  <h4 style="font-weight: 400; font-size: 14px; margin-bottom: 5px; margin-top:10px;">{{fields.label}}</h4>
                  <multiselect 
                    :options="fields.data" 
                    :allow-empty="true" 
                    :show-labels="false" 
                    :placeholder="fields.placeholder" 
                    v-model="inputForm[fields.vmodel]" 
                    @input="handleDropdownChange($event, fields)">
                  </multiselect>
                </div>

                <div v-if="fields.type == 'date'">
                  <h4 style="font-weight: 400; font-size: 14px; margin-bottom: 5px; margin-top:10px;">{{fields.label}}</h4>
                  <b-form-datepicker today-button reset-button close-button locale="en" :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }" v-model="inputForm[fields.vmodel]" :placeholder="fields.placeholder"></b-form-datepicker>
                </div>

                <div v-if="fields.type == 'heading'">
                  <h4 style="font-weight: 700; font-size: 16px; margin-bottom: 5px; margin-top:10px;">{{fields.label}}</h4>
                </div>

                <div v-if="fields.type == 'input'">
                  <h4 style="font-weight: 400; font-size: 14px; margin-bottom: 5px; margin-top:10px;">{{fields.label}}</h4>
                  <input type="text" class="form-control" v-model="inputForm[fields.vmodel]" :placeholder="fields.placeholder">
                </div>

                <div v-if="fields.type == 'textarea'">
                  <h4 style="font-weight: 400; font-size: 14px; margin-bottom: 5px; margin-top:10px;">{{fields.label}}</h4>
                  <textarea :cols="fields.cols" :rows="fields.rows" class="form-control" v-model="inputForm[fields.vmodel]" :placeholder="fields.placeholder"></textarea>
                </div>
                

                <!-- <div class="col-12" v-if="fields.type == 'date-time'">
                  

                  <div class="row">
                    <div class="col-6" v-for="item in fields.fields" :key="item.id">
                      
                      <div v-if="item.type == 'toggle-btn' && item.data_type == 'date'">
                          <h4 style="font-weight: 400; font-size: 14px; margin-bottom: 5px; margin-top:10px; margin-right: 5px;">{{item.label}}</h4>
                          <label class="switch" style="margin-top: 6px;">
                            <input type="checkbox" v-model="inputForm[item.vmodel]">
                            <div class="slider round"></div>
                          </label>
                      </div>
                      <div v-if="item.type == 'date' && !isDateTBD">
                        <h4 style="font-weight: 400; font-size: 14px; margin-bottom: 5px; margin-top:10px;">{{item.label}}</h4>
                        <b-form-datepicker v-if="item.type == 'date' && !isDateTBD" today-button reset-button close-button locale="en" :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }" v-model="inputForm[item.vmodel]" placeholder="Interaction Date"></b-form-datepicker>
                      </div>

                      <div v-if="item.type == 'toggle-btn' && item.data_type == 'time' && !isDateTBD">
                        <h4 style="font-weight: 400; font-size: 14px; margin-bottom: 5px; margin-top:10px; margin-right: 5px;">{{item.label}}</h4>
                        <label class="switch" style="margin-top: 6px;">
                          <input type="checkbox" v-model="inputForm[item.vmodel]">
                          <div class="slider round"></div>
                        </label>
                      </div>

                      <div v-if="item.type == 'time'">
                        <h4 style="font-weight: 400; font-size: 14px; margin-bottom: 5px; margin-top:10px;">{{item.label}}</h4>
                        <b-form-timepicker v-model="inputForm[item.vmodel]" locale="en" :placeholder="item.placeholder"></b-form-timepicker>
                      </div>
                    </div>
                  </div>

                </div> -->

                <!-- <div class="col-6" v-if="fields.type == 'date-time'">
                  <div v-for="item in fields.fields" :key="item.id">
                    <div v-if="item.type == 'time'">
                      <h4 style="font-weight: 400; font-size: 14px; margin-bottom: 5px; margin-top:10px;">{{item.label}}</h4>
                      <b-form-timepicker v-model="inputForm[item.vmodel]" locale="en" :placeholder="item.placeholder"></b-form-timepicker>
                    </div>
                  </div>
                </div> -->

                <div v-if="fields.type == 'comments'">
                  <h4 style="font-weight: 400; font-size: 14px; margin-bottom: 5px; margin-top:10px;">{{fields.label}}</h4>
                  <textarea cols="20" rows="6" class="form-control" v-model="inputForm[fields.vmodel]" :placeholder="fields.placeholder"></textarea>
                </div>

                <div v-if="fields.type == 'checkbox'">
                  <h4 style="font-weight: 400; font-size: 14px; margin-bottom: 5px; margin-top:10px;">{{fields.label}}</h4>
                  <b-form-checkbox-group v-model="inputForm[fields.vmodel]">
                    <b-form-checkbox v-for="(entry, index) in fields.data" :key="index" :value="entry.val">{{entry.val}}</b-form-checkbox>
                  </b-form-checkbox-group>
                </div>

                <div v-if="fields.type == 'radio'">
                  <h4 style="font-weight: 400; font-size: 14px; margin-bottom: 5px; margin-top:10px;">{{fields.label}}</h4>
                  <b-form-radio-group v-model="inputForm[fields.vmodel]">
                    <b-form-radio v-for="(entry, index) in fields.data" :key="index" :value="entry">{{entry}}</b-form-radio>
                  </b-form-radio-group>
                </div>

                <!-- <div class="col-12" v-if="fields.type == 'objectives-checkbox'">
                  <b-form-checkbox-group v-model="inputForm[fields.vmodel]" stacked>
                    <b-form-checkbox class="pb-2" v-for="entry in objForm" :key="entry.id" :value="entry.val">{{entry.val}}</b-form-checkbox>
                  </b-form-checkbox-group>
                </div> -->

                <div v-if="fields.type == 'kol'">
                  <h4 style="font-weight: 400; font-size: 14px; margin-bottom: 5px; margin-top:10px;">{{fields.label}}</h4>
                  <multiselect :options="items" :allow-empty="true" label="name" track-by="kol_id" :show-labels="false" :placeholder="fields.placeholder" :multiple="true" :clear-on-select="false" v-model="inputForm[fields.vmodel]" @search-change="getKOLs">
                    <template slot="tag" slot-scope="{ option, remove, disabled }"><span class="custom__tag"><img class="option__image" style="border-radius: 50%; width: 25px; height:25px;" :src="getPhoto(option.image)"><span class="option__desc">&nbsp;&nbsp;<span class="option__title">{{option.first_name }} {{option.last_name}}</span><span class="custom__remove" v-if="!disabled" @click.prevent @mousedown.prevent.stop="remove(option, $event)"><font-awesome-icon icon="times"/></span></span></span></template>
                    <template slot="option" slot-scope="props"><img class="option__image" style="border-radius: 50%; width: 40px; height:40px;" :src="getPhoto(props.option.image)"><span class="option__desc">&nbsp;&nbsp;<span class="option__title">{{ props.option.first_name }} {{props.option.last_name}}</span></span></template>              
                  </multiselect>
                  
                </div>

                <div v-if="fields.type == 'personnel'">
                  <h4 style="font-weight: 400; font-size: 14px; margin-bottom: 5px; margin-top:10px;">{{fields.label}}</h4>
                  <multiselect :options="users" :allow-empty="true" label="name" track-by="public_id" :show-labels="false" :placeholder="fields.placeholder" :multiple="true" v-model="inputForm[fields.vmodel]">
                    <template slot="tag" slot-scope="{ option, remove, disabled }">
                      <span class="custom__tag">
                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="32px" height="32px" viewBox="0 0 32 32" version="1.1" style="margin-right: 4px; margin-left: -11px;">
                          <circle fill="#aaa" width="32" height="32" cx="16" cy="16" r="16"/>
                          <text x="50%" y="50%" style="color: #ffffff;line-height: 1;font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;" alignment-baseline="middle" text-anchor="middle" font-size="13" font-weight="400" dy=".1em" dominant-baseline="middle" fill="#ffffff">{{option.first_name[0]}}{{option.last_name[0]}}</text>
                        </svg>
                        <span class="option__desc">&nbsp;&nbsp;<span class="option__title">{{option.first_name }} {{option.last_name}}</span><span class="custom__remove" v-if="!disabled" @click.prevent @mousedown.prevent.stop="remove(option, $event)"><font-awesome-icon icon="times"/></span></span>
                      </span>
                    </template>
                    <template slot="option" slot-scope="props">
                      <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="32px" height="32px" viewBox="0 0 32 32" version="1.1" style="margin-right: 5px; margin-left: 0px;">
                        <circle fill="#aaa" width="32" height="32" cx="16" cy="16" r="16"/>
                        <text x="50%" y="50%" style="color: #ffffff;line-height: 1;font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;" alignment-baseline="middle" text-anchor="middle" font-size="13" font-weight="400" dy=".1em" dominant-baseline="middle" fill="#ffffff">{{props.option.first_name[0]}}{{props.option.last_name[0]}}</text>
                      </svg>
                      <span class="option__desc">&nbsp;&nbsp;<span class="option__title">{{ props.option.first_name }} {{props.option.last_name}}</span></span>
                    </template>              
                  </multiselect>
                </div>
              </div>
              </div>
            <!-- </div> -->



            <!-- <div class="pb-3">
              <h4 style="font-weight: 400; font-size: 14px; margin-bottom: 5px; margin-top:10px;">ESE</h4>
              <multiselect :options="items" :allow-empty="true" label="first_name" track-by="kol_id" :show-labels="false" placeholder="Select ESE" :multiple="true" v-model="inputForm.ese">
                <template slot="tag" slot-scope="{ option, remove, disabled }"><span class="custom__tag"><img class="option__image" style="border-radius: 50%; width: 25px; height:25px;" :src="getPhoto(option.photo)"><span class="option__desc">&nbsp;&nbsp;<span class="option__title">{{option.first_name }} {{option.last_name}}</span><span class="custom__remove" v-if="!disabled" @click.prevent @mousedown.prevent.stop="remove(option, $event)"><font-awesome-icon icon="times"/></span></span></span></template>
                <template slot="option" slot-scope="props"><img class="option__image" style="border-radius: 50%; width: 40px; height:40px;" :src="getPhoto(props.option.photo)"><span class="option__desc">&nbsp;&nbsp;<span class="option__title">{{ props.option.first_name }} {{props.option.last_name}}</span></span></template>              
              </multiselect>
            </div>
            <div class="pb-3">
              <h5 style="font-weight: 700;">Meeting Info</h5>
              <div class="row">
                <div class="col-6">
                  <h4 style="font-weight: 400; font-size: 14px; margin-bottom: 5px; margin-top:10px;">Meeting Type</h4>
                  <multiselect :options="meetingType" :allow-empty="true" :show-labels="false" placeholder="Select Meeting Type" v-model="inputForm.meeting_type"></multiselect>
                </div>
                <div class="col-6">
                  <div class="d-flex justify-content-between">
                    <h4 style="font-weight: 400; font-size: 14px; margin-bottom: 5px; margin-top:10px;">Interaction Date</h4>
                    <div class="d-flex justify-content-end">
                        <h4 style="font-weight: 400; font-size: 14px; margin-bottom: 5px; margin-top:10px; margin-right: 5px;">Date TBD</h4>
                        <label class="switch" style="margin-top: 6px;">
                          <input type="checkbox" v-model="inputForm.date_tbd">
                          <div class="slider round"></div>
                        </label>
                    </div>
                  </div>
                  <b-form-datepicker today-button reset-button close-button locale="en" :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }" v-model="inputForm.interaction_date" placeholder="Interaction Date"></b-form-datepicker>
                </div>
              </div>
              <div class="row">
                <div class="col-6">
                  <h4 style="font-weight: 400; font-size: 14px; margin-bottom: 5px; margin-top:10px;">Start Time</h4>
                  <b-form-timepicker v-model="inputForm.start_time" locale="en" placeholder="Start Time"></b-form-timepicker>
                </div>
                <div class="col-6">
                  <div class="d-flex justify-content-between">
                    <h4 style="font-weight: 400; font-size: 14px; margin-bottom: 5px; margin-top:10px;">End Time</h4>
                    <div class="d-flex justify-content-end">
                      <h4 style="font-weight: 400; font-size: 14px; margin-bottom: 5px; margin-top:10px; margin-right: 5px;">Time TBD</h4>
                      <label class="switch" style="margin-top: 6px;">
                        <input type="checkbox" v-model="inputForm.date_tbd">
                        <div class="slider round"></div>
                      </label>
                    </div>
                  </div>                 
                  <b-form-timepicker v-model="inputForm.end_time" locale="en" placeholder="End Time"></b-form-timepicker>
                </div>
              </div>
            </div>
            <div class="pb-3">
              <h5 style="font-weight: 700;">Location</h5>
              <div class="row">
                <div class="col">
                  <multiselect :options="locations" :allow-empty="true" label="name" track-by="location_id" :multiple="false" :show-labels="false" placeholder="Select Location" v-model="inputForm.meeitng_location">
                    <template slot="tag" slot-scope="{ option, remove, disabled }"><span class="custom__tag"><span class="option__title">{{option.name }}</span><span class="custom__remove" v-if="!disabled" @click.prevent @mousedown.prevent.stop="remove(option, $event)"><font-awesome-icon icon="times"/></span></span></template>
                    <template slot="option" slot-scope="props">
                      <span class="option__desc">
                        <span style="font-weight: 700;">{{props.option.name}}<br>
                          <span style="font-size: 14px; font-weight: 400;">
                              <span style="color:goldenrod; font-weight: 600;">{{props.option.price}}</span> &bull; {{props.option.location_type}} &bull; {{props.option.distance}} Miles &bull; Takes Reservation: <span v-if="props.option.takes_reservations" class="badge badge-success">Yes</span><span v-if="!props.option.takes_reservations" class="badge badge-danger">No</span>
                          </span>
                        </span>
                      </span>
                    </template>              
                  </multiselect>
                </div>
              </div>
            </div>
            <div class="pb-3">
              <h5 style="font-weight: 700;">AZ Attendees</h5>
              <div class="row">
                <div class="col-6">
                  <h4 style="font-weight: 400; font-size: 14px; margin-bottom: 5px; margin-top:10px;">I am attending this interaction</h4>
                  <label class="switch">
                    <input type="checkbox" v-model="inputForm.date_tbd">
                    <div class="slider round"></div>
                  </label>
                </div>
                <div class="col-6">
                  <h4 style="font-weight: 400; font-size: 14px; margin-bottom: 5px; margin-top:10px;">I would like other personnell to attend this interaction</h4>
                  <label class="switch">
                    <input type="checkbox" v-model="inputForm.date_tbd">
                    <div class="slider round"></div>
                  </label>
                </div>
              </div> 
              <h4 style="font-weight: 400; font-size: 14px; margin-bottom: 5px; margin-top:10px;">Who would you like to attend?</h4>
              <b-form-checkbox-group
                  id="checkbox-group-2"
                  v-model="inputForm.additional_attendees"
                  name="flavour-2"
                >
                <b-form-checkbox value="orange">Orange</b-form-checkbox>
                <b-form-checkbox value="apple">Apple</b-form-checkbox>
                <b-form-checkbox value="pineapple">Pineapple</b-form-checkbox>
                <b-form-checkbox value="grape">Grape</b-form-checkbox>
              </b-form-checkbox-group>
        
              <h4 style="font-weight: 400; font-size: 14px; margin-bottom: 5px; margin-top:10px;">Perssonel Requests</h4>
              <textarea name="" id="" cols="30" rows="5" class="form-control" placeholder="Personnel Requests"></textarea>
            </div>
            <div class="pb-3">
              <h5 style="font-weight: 700;">Objectives</h5>
               <b-form-checkbox-group
                  id="checkbox-group-3"
                  v-model="inputForm.objectives"
                  name="flavour-3"
                  stacked
                >
                <b-form-checkbox value="orange" class="pb-2">Orange </b-form-checkbox>
                <b-form-checkbox value="apple" class="pb-2">Apple</b-form-checkbox>
                <b-form-checkbox value="pineapple" class="pb-2">Pineapple</b-form-checkbox>
                <b-form-checkbox value="grape" class="pb-2">Grape</b-form-checkbox>
              </b-form-checkbox-group>
            </div>
            <div class="pb-3">
              <h5 style="font-weight: 700;">Comments</h5>
              <textarea name="" id="" cols="30" rows="5" class="form-control" placeholder="Comments"></textarea>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import axios from 'axios'
import { forEach } from 'lodash'
// import { http, authHeader } from '@/config/base-url'
import http2 from '@/config/base'
export default {
  name: 'InteractionModal',
  props: ['title', 'editData'],
  data () {
    return {
      formFields: JSON.parse(localStorage.getItem("settings") || '{}').data.kol_interaction_form.fields,
      data: {},
      inputForm: {},
      formArray: [],
      formSubmitted: false,
      formValid: false,
      value: [],
      formMounted: false,
      dpvmodel: '',
      showError: false,
      items: [],
      locations: [],
      users: [],
      meetingType: ['Lunch', 'Dinner', 'Breakfast', 'Coffee'],
      objForm: [],
      conventionId: this.$route.params.name,
      isTimeTBD: false,
      isDateTBD: false,
      locationValue: {},
      virtualLocation: '',
      radioValues: {}
    }
  },
  methods: {
    close: function () {
      this.$emit('close')
    },
    isInvalid (item) {
      if(this.formSubmitted) {
        return (this.inputForm[item.vmodel] === null || this.inputForm[item.vmodel] === '') && item.required ? true : ''
      }
    },
    checkSelect: function(item) {
      if(this.formSubmitted) {
        if ((this.inputForm[item.vmodel] === null || this.inputForm[item.vmodel] === '') && item.required) {
          return 'invalid'
        } else if ((this.inputForm[item.vmodel] !== null || this.inputForm[item.vmodel] !== '') && item.required) {
          return 'valid'
        }
      }
    },
    checkFormField: function (item) {
      if(this.formSubmitted) {
        if ((this.inputForm[item.vmodel] === null || this.inputForm[item.vmodel] === '') && item.required) {
          return 'is-invalid'
        } else if ((this.inputForm[item.vmodel] !== null || this.inputForm[item.vmodel] !== '') && item.required) {
          return 'is-valid'
        }
      }
    },
    searchLocation: function(location) {
      this.locationValue = location
      console.log(location)
    },

    timeToggle: function(e) {
      console.log(e.target.checked)
      this.isTimeTBD = e.target.checked;
    },

    dateToggle: function(e) {
      console.log(e)
      console.log(e.target.checked)
      this.isDateTBD = e.target.checked;
      this.inputForm['date_tbd'] = e.target.checked
    },

    checkItem: function (item) {
      if ((this.inputForm[item.vmodel] !== '' || this.inputForm[item.vmodel] !== null) && item.required) {
        const index = this.formArray.indexOf(item)
        this.formArray.splice(index, 1)
        this.checkFormField(item)
      } else {
        this.checkFormField(item)
      }
      this.formSubmitted = false
      console.log('Check', this.formArray)
    },
    validateForm: function () {
      for (let i=0; i<this.formArray.length; i++) {
        if(this.inputForm[this.formArray[i]] === null || this.inputForm[this.formArray[i]] === '' ) {
          this.showError = true
          this.formValid = false
          break
        } else {
          this.showError = false
          this.formValid = true
        }
      }
    },
    formatTime: function(date) {
      if (date == 'TBD') {
        return 'TBD'
      } else {
        const d = new Date(date)
        console.log(d)
        let hr = d.getHours();
        let min = d.getMinutes();
        if (min < 10) {
            min = "0" + min;
        }
        let ampm = "AM";
        if( hr >= 12 ) {
          ampm = "PM";
        }
        if( hr > 12 ) {
          hr-= 12
        }
        return `${hr}:${min}`;
      }
    },
    collectCustomFormData: function() {
      let customValues = {}
      
      forEach(this.formFields, field => {
        if (field.form_type === "custom") {
          // Store value if it exists in inputForm
          if (this.inputForm[field.vmodel]) {
            customValues[field.vmodel] = this.inputForm[field.vmodel]
          }
        }
      })
      
      // Return just the object instead of trying to push it into an array
      console.log('Custom form values:', customValues)
      return customValues
    },
    saveForm: function () {

      // Get all radio values before saving
      // const radioItems = this.collectRadioData()
      // Get all custom form values
      const customItems = this.collectCustomFormData()
      
      // console.log('radio items:', radioItems)
      console.log('custom items:', customItems)

      //All fields are required
      // console.log(this.inputForm)
      if(this.inputForm['key_insights'] === undefined) {
        this.inputForm['key_insights'] = null
      }

      if(this.inputForm['brand'] === undefined) {
        this.inputForm['brand'] = null
      }

      // console.log(this.inputForm['attendees_object'])
      // console.log(this.inputForm['attendees_object'].map(({ name }) => name))
      const userArray = this.inputForm['attendees_object'].map(({ name }) => name)
      this.inputForm['attendees'] = userArray
      this.inputForm['client_brand'] = this.clientData.client_brand
      const idata = this.inputForm
      // console.log(idata)  
      this.inputForm['interaction_data'] = customItems

      console.log('save',this.inputForm)

      if(this.inputForm['id']) {
        http2.put(`v2/conventions/kols/${this.inputForm['id']}`, this.inputForm).then((res) => {
          console.log(res.data)
          // this.emptyFields();
          this.$emit('close')
        })
      } else {
        http2.post(`v2/conventions/kols`, this.inputForm).then((res) => {
          console.log(res.data)
          // this.emptyFields();
          this.$emit('close')
        })
      }
      
    },
   
    getData: function () {
       axios.get(`https://simpsonapi.com/v2/conventions/3x-data/${localStorage.getItem('project')}`).then(res => {
         console.log(res)
         this.clientData = res.data.data
        // this.getKOLs()
       })
    },
    getKOLs: function (query) {
      console.log(this.clientData.project_url)
      axios.get(`${this.clientData.project_url}${this.clientData.db_name}/kols/name/${query}`).then(res => {
         console.log(res)
         this.items = res.data.kols
       })
    },
    
    getPhoto: function (image) {
      // console.log('image', JSON.parse(localStorage.getItem('settings')).data.kol_image_url)
      if(JSON.parse(localStorage.getItem('settings')).data.kol_image_url) {
        return JSON.parse(localStorage.getItem('settings')).data.kol_image_url + image
      }
     
    },
    clearAllLocation () {
      this.locations = []
    },
    getObjectives: function() {
      http2.get(`v2/conventions/${this.conventionId}/objectives-mobile`).then((res) => {
        this.objForm = res.data.objectives
      })
    },
    getLocations: function () {
       http2.get(`v1/conventions/${this.conventionId}/meeting-locations`).then(res => {
        console.log(res.data.meeting_locations)
        const oldItem = { name: 'Virtual Meeting' }
        res.data.meeting_locations.unshift(oldItem)
        this.locations = res.data.meeting_locations
       })
    },
    // getKOLs: function () {
    //   console.log(this.clientData.project_url)
    //   axios.get(`${this.clientData.project_url}${this.clientData.db_name}/connex-kols`).then(res => {
    //      console.log(res)
    //      this.items = res.data.kols
    //    })
    // },
    getAttendees: function () {
      http2.get(`v2/conventions/interaction-users`).then(res => {
        this.users = res.data.users.filter(object => object.project_id === localStorage.getItem("project"))
        console.log(this.users)
        if(this.editData === null){
          // console.log('attendees', res.data.users.filter(object => object.public_id === JSON.parse(localStorage.getItem("user") || '{}').publicId))
          this.inputForm['attendees_object'] = res.data.users.filter(object => object.public_id === JSON.parse(localStorage.getItem("user") || '{}').publicId)
          console.log(this.inputForm['attendees_object'])
          
        }
      })
    },
    emptyFields: function() {
      if(Object.keys(this.formFields).length > 0) {
        this.formFields.forEach((items) => {
          items.fields.forEach((item) => {
            // if(item.type == 'checkbox') {
            //   formObjectives = item.data_mobile
            // }
            if(item.type == 'date-time') {
              item.fields.forEach((v) => {
                if(v.type == 'toggle-btn') {
                this.data[v.vmodel] = false
                } else {
                  this.data[v.vmodel] = null;  
                } 
              })
            } else {
              if(item.type == 'toggle-btn') {
                this.data[item.vmodel] = false
              } else {
                this.data[item.vmodel] = null;  
              }
              
            }        
          })
        })
        console.log(this.data)
      }
    },
    collectRadioData: function() {
      this.radioValues = {} // Reset as empty object
      
      forEach(this.formFields, field => {
        if (field.type === 'radio') {
          // Store selected value directly in object using field name as key
          if (this.inputForm[field.vmodel]) {
            this.radioValues[field.vmodel] = this.inputForm[field.vmodel]
          }
        }
      })
      
      console.log('Radio values:', this.radioValues)
    },
    handleDropdownChange: function(value, field) {
      console.log('Dropdown changed:', field.vmodel, value)
      if (field.form_type === 'custom') {
        this.inputForm[field.vmodel] = value
        console.log('Updated custom dropdown:', field.vmodel, value)
      } else {
        this.inputForm[field.vmodel] = value
      }
      console.log(this.inputForm)
    },
  },
  mounted () {
    this.getData()
    this.getAttendees()
    console.log('edit', this.editData)
    
    if(this.editData !== null) {
      this.inputForm = null
      this.inputForm = this.editData
      
      // Parse interaction_data if it exists and is a string
    
        // Load the parsed data back into form fields
      forEach(this.formFields, field => {
        if (field.form_type === "custom" && this.inputForm['interaction_data'][field.vmodel]) {
          // Update the input form with the saved value
          this.inputForm[field.vmodel] = this.inputForm['interaction_data'][field.vmodel]
        }
      })
        
      
    
      
      console.log('input edit', this.inputForm)
    }
  } 
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.vdp-datepicker input.form-control {
  background-color:#FFF;
}
</style>
<style scoped>

 .modal-mask {
  position: fixed;
  z-index: 1040;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  display: table;
  transition: opacity .3s ease;
}

.modal-body {
  max-height: calc(100vh - 110px);
  overflow-y: auto;
}

.modal-title {
  font-size: 1.5em;
  padding-top: 5px;
}

.close:focus {
  outline: none;
}

/* Rounded sliders */

.switch {
    position: relative;
    display: inline-block;
    width: 45px;
    height: 18px;
}

.switch input {
    display: none;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 2px;
    bottom: 1px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked+.slider {
    background-color: #009370;
}

input:focus+.slider {
    box-shadow: 0 0 1px #009370;
}

input:checked+.slider:before {
    -webkit-transform: translateX(25px);
    -ms-transform: translateX(25px);
    transform: translateX(25px);
}

.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

.custom__tag {
      display: inline-block;
    padding: 3px 12px;
    background: #f1f1f1;
    margin-right: 8px;
    margin-bottom: 8px;
    border-radius: 10px;
}

.option__title {
  padding-left: 5px;
}

.custom__remove {
  padding-left: 5px;
  cursor: pointer;
}

.multiselect__clear {
    position: absolute;
    right: 1px;
    padding-top: 8px;
    height: 50px;
    width: 50px;
    display: block;
    cursor: pointer;
    z-index: 2;
    color: #ccc;
}
</style>
